<template>
    <div class="foot_box">
        <div class="title_upro">
            <span>UPRO - MARINE</span>
        </div>
        <div class="henxian" style="height: 5px; width: 100%;background-color: #00A887;margin-top: 10px;">

        </div>
        <div class="foot_content">

            <div class="content_title content_title_nav">
                <div>Contact Us </div>
                <div></div>
                <div>Navigation</div>
            </div>
            <div class="content_title" style="text-align: start;">
                <div style="font-size: 20px;">
                    <div style="padding: 1%;color: #fff;">
                        <!-- <p style="font-size: 20px;font-weight: 500;font-family: 'Montserrat', sans-serif;">
                        SHANGHAI UPRO</p>
                        <p style="font-size: 20px;font-weight: 500;font-family: 'Montserrat', sans-serif;padding-top: 15px;">MARINE SYSTEM CO., LTD.</p> -->
                        <img src="@/assets/logo-b.png" style="max-width: 150px;" alt="">
                        <p style="font-size: 16px;padding-top: 10px;">Tel :  +86 21 62162135</p>
                        <p style="font-size: 16px;padding-top: 10px;">Tel :  +86 21 6044 2331</p>
                        <p style="font-size: 16px;padding-top: 10px;">Email : info@uxgas.com</p>
                        <p style="font-size: 16px;padding-top: 10px;">17th Floor, Innovation Building, Putuo District, Shanghai, China</p>
                    </div>
                </div>
                <!-- <div class="maps" style="font-size: 20px;">
                    <baidu-map class="map" ak="0yNijLKpCQV4tplf66hZ8sbWCTTrnZSH" v="3.0" type="API" :center="{ lng: 121.5688834, lat: 31.1646523 }" :zoom="50" scroll-wheel-zoom>
                    <bm-marker :position="{ lng: 121.5688834, lat: 31.1646523 }" :dragging="false" animation="BMAP_ANIMATION_BOUNCE">
                        <bm-label class="bm_label" content="UPRO" />
                        </bm-marker>
                    </baidu-map>
                </div> -->
                <div class="laixin" style="padding-right: 1%;">
                    <input type="text" placeholder="Your Name*" style="width: 100%; height: 40px;">
                    <input type="text" placeholder="Your Mail*" name="" id="" style="width: 100%; height: 40px;">
                    <input type="text" placeholder="Your Phone*" name="" id="" style="width: 100%; height: 40px;">
                    <textarea type="text" 
                    placeholder="Your Message.."
                    required aria-required="true" aria-invalid="true" style="width: 100%;height: 80px;margin-top: 10px;background: #fdf2d0"></textarea>
                    <button type="submit" class="button_hover" style="background:#222 none repeat scroll 0 0;color: #fff; width: 50%;padding: 15px 0;
                    border-radius: 5px; ">
                    SEND MESSAGE</button>
                </div>
                <div class="maps" style="font-size: 20px;">
                    <!-- <baidu-map class="map" ak="0yNijLKpCQV4tplf66hZ8sbWCTTrnZSH" v="3.0" type="API" :center="{ lng: 121.5688834, lat: 31.1646523 }" :zoom="50" scroll-wheel-zoom>
                    <bm-marker :position="{ lng: 121.5688834, lat: 31.1646523 }" :dragging="false" animation="BMAP_ANIMATION_BOUNCE">
                        <bm-label class="bm_label" content="UPRO-MARINE" />
                        </bm-marker>
                    </baidu-map> -->
                </div>
            </div>
        </div>
        <el-backtop :right="10" :bottom="10" style="background-color: rgb(0, 168, 135);color: #fff;"/>
        <!-- <div class="foot_copy">
            <button @click="scrollToTop" class="scroll-top-button">Back to Top</button>
            <span>© 2024 UPRO Marine. All rights reserved.</span>
        </div> -->



    </div>
</template>
<script>
// import { BaiduMap } from 'vue-baidu-map-3x'
export default {
  methods: {
    // 将页面滚动到顶部
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动
      });
    }
  }
}
</script>
<style scoped>
.scroll-top-button {
  position: fixed;
  bottom: 20px;
  right: 5px;
  z-index: 999; /* 确保按钮在最顶层 */
  background-color: rgb(0, 168, 135);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scroll-top-button:hover {
  background-color: #0056b3;
}



.foot_box {
    color: #fff;
    text-align: center;
    width: 100%;
    /* background-color: #00215f ;01417e */
    background-color: #01417e ;
    border-radius: 15px 15px 0 0;
    margin-top: 80px;
}
.foot_content {
    width: 80%;
    margin: 0 auto;
}
.title_upro {
    font-size: 45px;
    font-weight: 600;
    text-align: center;
    padding-top: 10px;
}
.content_title {
    display: flex;
    padding-top: 25px;
}
.content_title>div {
    width: 33%;
    font-size: 30px;
    font-weight: 500;
}
.content_title p {
    padding-bottom: 15px;
}
.list_p {
    cursor: pointer;
}
.list_p :hover{
    color: #00A887;
}
.foot_copy {
    padding: 15px 0;
}
.button_hover :hover {
    background-color: #fff;
    color: #222;
}
.maps{
  width: 1200px;
  height: 100%;
}
.map {
  width: 100%;
  height: 300px;
}
@media (max-width: 480px) {
    .content_title>div{
        width: 100%;
    }
    .content_title {
        display: block;
    }
    .content_title_nav {
        display: none;
    }
    .title_upro,.henxian {
        display: none;
    }
    .foot_box {
        border-radius: 0px;
    }
    .laixin {
        display: none;
    }
    .content_title img {
        display: block;
        margin: 0 auto;
    }
    .foot_box {
        margin-top: 15px;
    }
    .foot_content {
        width: 98%;
    }
    .maps {
        height: 300px;
    }
}
</style>